import React, {useState, createRef} from 'react';
import styles from './index.module.less';
import Header from '../../../components/header';
import Bottom from '../../../components/contact';
import Reserve from '../../../components/reserve';
import BasicLayout from '../../../layout/basicLayout';
import Module from '../../../components/module';
import SwiperS from './swiper';
import 'swiper/swiper.less';
import 'swiper/components/navigation/navigation.less';
import 'swiper/components/pagination/pagination.less';
import 'swiper/components/scrollbar/scrollbar.less';
import SwiperCore, {
    A11y,
    Navigation,
    Pagination,
    Scrollbar,
    Mousewheel,
    Virtual,
    Autoplay,
} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {dutyList} from '../../../contant';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel, Virtual, Autoplay]);
const Join = () => {
    const [current, setCurrent] = useState(0);
    const [swiperObj, setSwiperObj] = useState(null);
    let swiperRef = createRef();
    const slideChange = (e) => {
        setCurrent(e.activeIndex);
    };
    const onTabClick = (e) => {
        swiperObj.slideTo(e);
    };

    return <BasicLayout>
        <Header fixed/>
        <div className={'content_wrapper'}>
            <SwiperS/>
            <Module title={'社会招聘'} eng={'Social Recruitment'}>
                <div className={styles.type_wrapper}>
                    {
                        dutyList.map((ele, index) => {
                            return <div onClick={() => {
                                onTabClick(index);
                            }}
                                        key={index}
                                        className={`${styles.tab_item} ${current ===
                                        index
                                            ? styles.selected
                                            : ''}`}>{ele.title}</div>;
                        })
                    }
                </div>

                <div className={styles.swiper_wrapper}>
                    <Swiper
                        ref={swiperRef}
                        grabCursor
                        autoplay
                        navigation
                        onSlideChange={slideChange}
                        onSwiper={swiper => (setSwiperObj(swiper))}
                    >
                        {
                            dutyList.map((ele, index) => {
                                return <SwiperSlide key={index}>
                                    <div className={styles.content_wrapper}>
                                        <div className={styles.content_left}>
                                            <div
                                                className={styles.title}>{ele.title}</div>
                                            <div className={styles.sub_title}>
                                                岗位职责：
                                            </div>
                                            {ele.content}
                                        </div>
                                        <div className={styles.content_right}>
                                            <div className={styles.sub_title}>
                                                任职要求：
                                            </div>
                                            {ele.duty}
                                            <div className={styles.contact}>
                                                <div className={styles.remark_title}>{'联系我们：'}</div>
                                                <div className={styles.remark}>
                                                    {'请发送个人简历及个人作品至masn@toplion.com.cn'}<br/>
                                                    {'注明申请职位和入职时间，我们会在一周内回复。'}<br/>
                                                    {'若有疑问，可拨打电话0531-58626000详询。'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>;
                            })
                        }
                    </Swiper>
                </div>
            </Module>
        </div>
        <Bottom/>
        <Reserve/>
    </BasicLayout>;
};
export default Join;
